.gameMain {
	display: flex;
	flex: 1;
	flex-direction: column;

	align-items: center;

	min-height: 100vh;

	background-color: black;
}

.gameHeader {
	display: flex;
	width: 100%;
	flex-direction: column;
	align-items: center;

	background-image: url(../ressources/images/background/img-background-header.png);
	background-repeat: no-repeat;
	background-position: bottom;
	background-size: cover;
}

.gameLogo {
	max-width: min(50vw, 250px);
	margin-top: min(200px, 20%);
	margin-bottom: min(100px, 10%);
}
.gameLogo > img {
	max-width: 100%;
	max-height: 100%;
}

.gameHeaderTitle {
	max-width: 90%;
	margin-bottom: min(100px, 10%);

	background-image: url(../ressources/images/bg-title-pink.png);
	background-repeat: no-repeat;
	background-position: center;
	background-size: 50% 100%;

	text-align: center;
}

.gameHeaderText {
	width: 90%;
	max-width: 800px;
	margin-bottom: min(200px, 20%);

	text-align: center;
}


.gamePicture {
	display: flex;
	width: 100%;
	flex-direction: column;
	align-items: center;

	background-image: url(../ressources/images/background/img-background-team.png);
	background-repeat: no-repeat;
	background-position: center;
	background-size: 100% auto;
}

.gamePictureText {
	width: 90%;
	max-width: 500px;
	margin: min(50px, 5%) 0;

	text-align: center;
}

.gamePictureTeam {
	width: 90%;
	max-width: 800px;
	margin-bottom: min(50px, 5%);
}
.gamePictureTeam > img {
	max-width: 100%;
}

.gamePictureUserContainer {
	display: flex;
	width: 90%;
	max-width: 800px;

	flex-wrap: wrap;
}

.gamePictureUser {
	display: flex;
	flex: 1;
	align-items: center;
	min-width: 200px;
	flex-direction: column;
}

.gamePictureUser > div {
	padding: 10px;
	text-align: center;
}

.gamePictureUser > div:first-of-type {
	width: 180px;
	height: 180px;
}

.gamePictureUser > div > img {
	width: 100%;
	height: 100%;
	object-fit: cover;
	/* border: 2px solid #fff; */
	border-radius: 50%;
}

.gamePictureUser {
	margin-bottom: min(50px, 5%);
}



.gamePart {
	display: flex;
	width: 100%;
	flex-direction: column;
	align-items: center;

	margin-top: min(100px, 10%);
	padding-top: min(100px, 10%);
	padding-bottom: min(200px, 20%);

	background-image: url(../ressources/images/background/img-background-replay.png);
	background-repeat: no-repeat;
	background-position: top;
	background-size: cover;
}

.gamePart > span {
	text-align: center;
}

.gamePartContainer {
	display: flex;
	width: 90%;
	max-width: 1200px;
	flex-direction: column;
	align-items: center;
	margin-top: min(50px, 5%);
}

.gamePartContainer > div {
	display: flex;
	width: 100%;
	height: 300px;
	margin-top: 20px;
}

.gameVideo {
	position: relative;
	display: flex;
	flex: 1;

	justify-content: center;
	align-items: center;

	margin-right: 20px;

	background-color: black;
}

.gameVideo > img:first-of-type {
	cursor: pointer;
	position: absolute;
	top: 10px;
	left: 10px;

	max-width: 10%;
}

.gameVideo > img:last-of-type {
	cursor: pointer;
	position: absolute;
	max-width: 15%;
}
.gameVideo > video {
	display: flex;
	flex: 1;
	max-height: 100%;
	max-width: 100%;
}

.gameVideo > div {
	display: flex;
	flex: 1;
	max-height: 100%;
	max-width: 100%;
}

.gameVideo > div > img {
	display: flex;
	flex: 1;
	max-height: 100%;
	max-width: 100%;
}

.gameRank {
	display: flex;
	flex: 1;
	flex-direction: column;

	padding-left: 20px;
	border-left: 2px solid #fff;
}

.gameRank > div {
	display: flex;
	max-height: 200px;
	flex-flow: column wrap;
	margin: 20px 0;
}

.gameRankItem {
	margin-bottom: 5px;
}




.gameSocial {
	display: flex;
	width: 100%;
	flex-direction: column;
	align-items: center;
	text-align: center;
}

.gameSocialText {
	font-family: 'RobotoCondensed';
	font-size: 28px;

	margin-top: min(50px, 5%);
	margin-bottom: min(50px, 5%);
}

.gameSocial > div > a {
	text-decoration: none;
	margin: 0 10px;
}



.gameFooter {
	display: flex;
	width: 100%;
	align-items: center;
	justify-content: center;
	text-align: center;
	margin-top: min(100px, 10%);

	background-color: #272727;
}

.gameFooter > img {
	margin: 10px;
	max-width: 30%;
}

@media screen and (max-width: 630px) {
	.gamePictureUser {
		min-width: unset;
	}

	.gamePartContainer > div {
		flex-direction: column;
		height: auto;
	}

	.gameVideo {
		width: 100%;
		min-height: 150px;
		margin-left: 0;
	}

	.gameRank {
		margin-top: 20px;
		padding: 0;
		border: none;

		text-align: center;
	}

	.gameRank > div {
		max-height: unset;
		flex-wrap: nowrap;
	}
}