.formMain {
	display: flex;
	flex: 1;
	flex-direction: column;

	justify-content: center;
	align-items: center;

	min-height: 100vh;

	background-color: black;
	background-image: url(../ressources/images/background/img-background-team.png);
	background-repeat: no-repeat;
	background-position: center;
	background-size: 100% auto;
}

.formLogo {
	max-width: min(50vw, 150px);
	margin: 20px 0;
}
.formLogo > img {
	max-width: 100%;
	max-height: 100%;
}

.formText {
	display: flex;
	max-width: 90%;
	flex-direction: column;
	align-items: center;
	text-align: center;
}

.formUserContainer {
	display: flex;
	flex-direction: column;

	align-items: center;
	margin-top: 20px;
	width: 100%;
	max-width: 500px;
	background-color: rgba(255, 255, 255, 0.03);
}

.formUserTitle {
	margin: 20px 0;
}

.formUserInput {
	display: flex;
	width: 80%;
	flex-direction: column;
}

.formUserInput > input {
	width: 100%;
	padding: 10px 0;
	margin-top: 10px;
	text-align: center;

	background-color: rgba(94, 94, 94, 0.5);
	border-radius: 10px;
	border: 2px solid rgba(94, 94, 94, 0.5);
}

.formInputError {
	border-color: red !important;
	background-color: rgba(255, 0, 0, 0.2) !important;
	color: red;
}

.formInputValid {
	border-color: rgb(37, 164, 124) !important;
	background-color: rgba(37, 164, 124, 0.2) !important;
	color: rgb(37, 164, 124);
}

.formUserContainer.close {
	flex-direction: row;
	padding: 10px 0;
}
.formUserContainerImage {
	display: flex;
	width: 80px;
	justify-content: center;
	align-items: center;
}
.formUserContainerImage > img {
	max-width: 50px;
}

.formUserContainerInfo {
	display: flex;
	flex: 1;
	flex-direction: column;
}
.formUserContainerInfo > span:last-of-type {
	opacity: 0.5;
}
.formUserContainerIcon {
	margin: 0 10%;
}
.formUserContainerIcon > img {
	max-width: 20px;
}

.formUserText {
	display: flex;
	width: 90%;
	color: #fff;

	margin: 10px 0;
}

.formConfirm {
	display: flex;
	margin-top: 20px;
	width: 100%;
	max-width: 500px;
}

.formConfirm > div {
	display: flex;
	justify-content: center;
	align-items: center;
	cursor: pointer;
	max-width: 15px;
	min-width: 15px;
	max-height: 15px;
	min-height: 15px;
	margin-top: 5px;
	margin-right: 5px;
	border-radius: 50%;
	border: 2px solid #fff;
	background-color: rgba(94, 94, 94, 0.5);
}

.formConfirm.active > div {
	position: relative;
	background-color: rgba(37, 164, 124, 0.5);
	border-color: rgb(37, 164, 124);
}
.formConfirm.active > div > img {
	position: absolute;
	max-width: 100%;
	left: 2px;
	top: -2px
}
/* .formConfirm.active > div::after {
	content: 'v';
	color: #fff
} */

.formButton {
	display: flex;
	align-items: center;
	justify-content: center;
	margin: 20px 0;
	padding: 20px 0;
	border-radius: 10px;
	width: 90%;
	max-width: 500px;

	background-color: #ED6E64;
	opacity: 0.5;
}
.formButton.active {
	cursor: pointer;
	opacity: 1;
}

.formButton > span {
	margin: 0 20px;
	text-align: center;
}