@font-face {
	font-family: 'RobotoCondensed';
  src: url('./ressources/fonts/RobotoCondensed-Regular.ttf');
}

@font-face {
	font-family: 'RobotoCondensed';
  src: url('./ressources/fonts/RobotoCondensed-Bold.ttf');
  
  font-weight: bold;
}

@font-face {
	font-family: 'RobotoCondensed';
  src: url('./ressources/fonts/RobotoCondensed-Italic.ttf');
  
  font-style: italic;
}

@font-face {
	font-family: 'RobotoCondensed';
  src: url('./ressources/fonts/RobotoCondensed-BoldItalic.ttf');
  
  font-weight: bold;
  font-style: italic;
}

@font-face {
	font-family: 'Libel Suit';
  src: url('./ressources/fonts/libel-suit-rg.otf');
}

body {
  margin: 0;
  font-family: 'RobotoCondensed', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.title {
  font-family: 'Libel Suit';
  font-size: 48px;
  color: #fff;
}

.titleCategory {
  font-family: 'Libel Suit';
  font-size: 40px;
  color: #fff;
}

.titleRank {
  font-family: 'Libel Suit';
  font-size: 31px;
  color: #fff;
}

.textBig {
  font-family: 'RobotoCondensed';
  font-size: 28px;
  color: #fff;
}

.text {
  font-family: 'RobotoCondensed';
  font-size: 20px;
  color: #fff;
}
.text.bold {
  font-weight: bold;
}

.textFooter {
  font-family: 'RobotoCondensed';
  font-size: 17px;
  font-style: italic;
  color: #B7B7B7;
}

.red {
  color: #ED6E64;
}
.yellow {
  color: #F4D37F
}
.underline {
  cursor: pointer;
  text-decoration: underline;
}

.text > a {
  cursor: pointer;
  color: #ED6E64;
  text-decoration: underline;
}

/* input[type="date"]::-webkit-inner-spin-button,
input[type="date"]::-webkit-calendar-picker-indicator {
    display: none;
    -webkit-appearance: none;
} */