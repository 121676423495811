.mainAdmin {
	padding: 10px;
}

.adminLogin {
	display: flex;
	flex-direction: column;
	align-items: center;
	margin-top: 20px;
}

.tableAdmin {
	width: 100%;
	height: 90%;
	border-collapse: collapse;
}

.tableAdmin th {
	background-color: #547C9B;
	/* font-family: BebasNeue; */
	font-weight: 600;
	padding: 5px;
	color: #ffffff;	
	border: 1px solid #7390AD !important;
}

.tableAdmin td {
	background-color: #A7B8CC;
	border: 1px solid #7390AD !important;
	text-align: center;
	padding: 5px;
}

.tableAdmin td > div {
	font-family: ZillaSlab;
	color: #0c324d;
}

.bulleAdmin {
	display: flex;
	justify-content: space-around;
	margin-bottom: 20px;
}

.bulleAdmin > div {
	display: flex;
	flex-direction: column;
	justify-content: center;
	background-color: #A7B8CC;
	padding: 15px;
	border-radius: 20px;
	border: 2px solid #547C9B;

	text-align: center;
	/* font-family: BebasNeue; */
	font-weight: 600;
	font-size: 20px;
}

.bulleAdmin > div > span:last-of-type {
	font-size: 40px;
	margin-top: 10px;
}

.adminMultiDelete {
	cursor: pointer;
	position: fixed;
	bottom: 10px;
	right: 20px;
	background-color: #547C9B;
	border: 1px solid #ffffff;
	padding: 12px 24px;
	border-radius: 5px;
	
	color: #ffffff;
	font-size: 20px;
	font-weight: 600;
}

.loaderContainer {
	display: flex;
	justify-content: center;
	margin-top: 20px;
}

.loader {
	border: 4px solid #f3f3f3; /* Light grey */
	border-top: 4px solid #3498db; /* Blue */
	border-radius: 50%;
	width: 20px;
	height: 20px;
	animation: spin 2s linear infinite;
  }
  
  @keyframes spin {
	0% { transform: rotate(0deg); }
	100% { transform: rotate(360deg); }
  }